import { takeLatest, call, put, all } from 'redux-saga/effects'
import { normalize } from 'normalizr'
import { push as pushHistory } from 'react-router-redux'

import actions, { constants } from 'common/actions/user'
import snackbarActions from 'common/actions/snackbar'

import * as schemas from 'schemas'
import * as api from 'api/user'
import * as Strings from 'constants/Strings'
import * as Routes from 'constants/Routes'

export function* onLoadUsers() {
  yield put(actions.loadUsersRequest.start())

  try {
    const { users } = yield call(api.loadUsers)
    const norm = yield call(normalize, users, [schemas.user])
    yield put(actions.loadUsersRequest.success(norm))
  } catch (err) {
    yield put(actions.loadUsersRequest.failure(err))
    yield put(snackbarActions.showSnackbar(Strings.WHOOPS))
    yield put(pushHistory(Routes.LOGIN))
  }
}

export function* onUpdateUserPremium({ payload: data }) {
  yield put(actions.updateUserPremiumRequest.start())
  try {
    yield call(api.updateUserPremium, data.data, data.customerId)

    yield put(actions.updateUserPremiumRequest.success({ data }))
  } catch (err) {
    yield put(actions.updateUserPremiumRequest.failure(err))
    yield put(snackbarActions.showSnackbar(Strings.WHOOPS))
  }
}

export function* onUpdateUserApiService({ payload: data }) {
  yield put(actions.updateUserApiServiceRequest.start())
  try {
    yield call(api.updateUserApiService, data.data, data.customerId)

    yield put(actions.updateUserApiServiceRequest.success({ data }))
  } catch (err) {
    yield put(actions.updateUserApiServiceRequest.failure(err))
    yield put(snackbarActions.showSnackbar(Strings.WHOOPS))
  }
}

export function* onUpdateUserLimit({ payload: data }) {
  yield put(actions.updateUserLimitRequest.start())
  try {
    yield call(api.updateUserLimit, data.data, data.customerId)

    yield put(actions.updateUserLimitRequest.success({ data }))
    yield put(snackbarActions.showSnackbar(Strings.SUCCESSFUL_UPDATED_USER_LIMIT))
  } catch (err) {
    yield put(actions.updateUserLimitRequest.failure(err))
    yield put(snackbarActions.showSnackbar(Strings.WHOOPS))
  }
}

export function* onUpdateUserApiServiceLimit({ payload: data }) {
  yield put(actions.updateUserApiServiceLimitRequest.start())
  try {
    yield call(api.updateUserApiServiceLimit, data.data, data.customerId)

    yield put(actions.updateUserApiServiceLimitRequest.success({ data }))
    yield put(snackbarActions.showSnackbar(Strings.SUCCESSFUL_UPDATED_USER_API_SERVICE_LIMIT))
  } catch (err) {
    yield put(actions.updateUserApiServiceLimitRequest.failure(err))
    yield put(snackbarActions.showSnackbar(Strings.WHOOPS))
  }
}

export function* onUpdateUserDisabled({ payload: data }) {
  yield put(actions.updateUserDisabledRequest.start())
  try {
    yield call(api.updateUserDisabled, data.data, data.customerId)

    yield put(actions.updateUserDisabledRequest.success({ data }))
  } catch (err) {
    yield put(actions.updateUserDisabledRequest.failure(err))
    yield put(snackbarActions.showSnackbar(Strings.WHOOPS))
  }
}

export default function* watchUser() {
  yield all([
    takeLatest(constants.LOAD_USERS, onLoadUsers),
    takeLatest(constants.UPDATE_USER_PREMIUM, onUpdateUserPremium),
    takeLatest(constants.UPDATE_USER_API_SERVICE, onUpdateUserApiService),
    takeLatest(constants.UPDATE_USER_DISABLED, onUpdateUserDisabled),
    takeLatest(constants.UPDATE_USER_LIMIT, onUpdateUserLimit),
    takeLatest(constants.UPDATE_USER_API_SERVICE_LIMIT, onUpdateUserApiServiceLimit)
  ])
}
